import Axios from "../utils/axios";
import httpConfig from "./env"
// VUE_APP_SERVER_URL1 = http://passport.gamebook.vip/
// VUE_APP_SERVER_URL2 = http://my.gamebook.vip/
// VUE_APP_SERVER_URL3 = http://api-play.gamebook.vip/
// VUE_APP_SERVER_URL5 = http://pay.gamebook.vip
let api1 = httpConfig.api1;
let api2 = httpConfig.api2;
let api3 = httpConfig.api3;
let api5 = httpConfig.api5;
export default {
    getConfig: () => { //获取配置
        return Axios.post(api1 + "/app/config", {}, {})
    },
    getGodInfo: (obj) => { //获取用户详情
        return Axios.post(api3 + "/home/share_show_info", {...obj}, {})
    },
    getSkillDetails: (obj) => { //获取技能卡详情
        return Axios.post(api3 + "/home/share_skill_detail", {...obj}, {})
    },
    getSkillComments: (obj) => { //获取技能卡评价
        return Axios.post(api3 + "/home/share_skill_comments", {...obj}, {})
    },
    getUserInfo: (obj) => { //
        return Axios.post(api5 + "/home/user_info", {...obj}, {})
    },
    getPriceList: (obj) => { //价格列表
        return Axios.post(api5 + "/home/prices", {...obj}, {})
    },
    submitProduct: (obj) => { //发起支付
        return Axios.post(api5 + "/home/submit", {...obj}, {})
    },
    productResult: (obj) => { //充值结果轮询
        return Axios.post(api5 + "/home/query_result", {...obj}, {})
    },
    channelObtain: (obj) => { //渠道包下载链接
        return Axios.post(api1 + "/app/download", {...obj}, {})
    },
    getUserInfos: (obj) => { //根据用户id查询用户信息
        return Axios.post(api5 + "/home/user_info_byid", {...obj}, {})
    },
    getNewsList: (obj) => { //获取咨询列表
        return Axios.post(api3 + "/index/news_list", {...obj}, {})
    },
    getNewsDetails: (obj) => { //获取新闻详情
        return Axios.post(api3 + "/index/news_info", {...obj}, {})
    },
    getInternetBarGameList: () => { //网吧游戏列表
        return Axios.post(api3 + "/cybercafe/game_list", {}, {})
    },
    getInternetBarCommentList: () => { //大神评论列表
        return Axios.post(api3 + "/cybercafe/god_list", {}, {})
    },
    getInternetBarGodList: (obj) => { //大神列表
        return Axios.post(api3 + "/god/lists", {...obj}, {})
    },
    sendOpenCyber: () => { //发送网吧流量统计
        const param = {
            url: location.href
        }
        return Axios.post(api3 + "/cybercafe/open_cyber_web", param, {})
    },
    getOrderInfo:(obj) =>{
        return Axios.post(api5 + "/home/get_order_info", {...obj}, {})
    },
    getEnterRoom() {
        return Axios.post(api3 + "/home/get_cyber_rand_room", {}, {})
    }


}
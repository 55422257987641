import Vue from 'vue'
import router from 'vue-router'
import HomePage from "@/views/Homepage/HomePage";
import Mobile from "@/views/Mobile/Mobile";
import Mobile2 from "@/views/Mobile/Mobile2";
Vue.use(router);
// 获取原型对象上的push函数
const originalPush = router.prototype.push
// 修改原型对象中的push方法
router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const vueRouter = new router({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes: [
        {
            path: '/startpage',
            name: 'startpage',
            component: () => import( '@/components/startPage/startPage'),
        },
        {
            path: '/startpageMobile',
            name: 'startpageMobile',
            component: () => import( '@/components/startPage/startPageMobile'),
        },
        {
            path: '/',
            name: 'HomePage',
            component: HomePage
        },{
            path: '/wangluo',
            name: 'HomePage',
            component: HomePage
        },
        {
            path: '/pay',
            name: 'pay',
            component: () => import( '@/views/pay/payPage')
        },
        {
            path: '/h5pay',
            name: 'h5pay',
            component: () => import( '@/components/moblePay/moblePay')
        },
        {
            path: '/h5payconfirm',
            name: 'h5payconfirm',
            component: () => import( '@/components/moblePay/payload/payload'),
            children: []
        },
        {
            path: '/play_h5pay',
            name: 'playh5pay',
            component: () => import( '@/components/moblePay/payload/play_h5pay'),
            children: []
        },
        {
            path: '/h5pay_fail',
            name: 'fail',
            component: () => import( '@/components/moblePay/payload/fail.vue'),
        }, {
            path: '/h5pay_callback',
            name: 'callback',
            component: () => import( '@/components/moblePay/payload/callback.vue'),
        },
        {
            path: '/h5pay_success',
            name: 'success',
            component: () => import( '@/components/moblePay/payload/success.vue'),
        },
        {
            path: '/start',
            name: 'start',
            component: () => import( '@/components/startPage/startPageOnce')
        },
        {
            path: '/Mobile',
            name: 'Mobile',
            component: Mobile
        },{
            path: '/Mobile2',
            name: 'Mobile2',
            component: Mobile2
        }, {
            path: '/details',
            name: 'Details',
            component: () => import( '@/views/Share/details/index'),
            meta: {
                title: '趣开黑'
            }
        }, {
            path: '/skills',
            name: '/Skills',
            component: () => import( '@/views/Share/skills/index'),
            meta: {
                title: '趣开黑'
            }
        }, {
            path: '/authentication',
            name: '/Authentication',
            component: () => import( '@/views/Authentication/authentication'),
            meta: {
                title: '大神认证'
            }
        },{
            path: '/news',
            name: 'News',
            component: () => import( '@/views/News/newsList'),
            meta: {
                title: '趣开黑-资讯'
            }
        },{
            path: '/internet',
            name: 'Internet',
            component: () => import( '@/views/internetBar/internetBar'),
        },{
            path: '/landing',
            name: 'Landing',
            component: () => import( '@/views/Landing/landing'),
        }]
})


function isMobileDevice() { //判断当前设备是否为移动端
    const userAgent = navigator.userAgent;
    const mobileKeywords = ["Android", "webOS", "iPhone", "iPad", "iPod", "BlackBerry", "Windows Phone"];
    for (const keyword of mobileKeywords) {
        if (userAgent.includes(keyword)) {
            return true;
        }
    }
    return false;
}


function getParams() {
    let url = location.search; //获取url中"?"符后的字串
    let theRequest = new Object();
    if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    return theRequest;
}

vueRouter.beforeEach((to, from, next) => {
    //进行判断，如果to路径没有匹配到现有的任何一个路由
    //作用：当to的路由为空时不跳转，同时当from的路由也为空时，则跳转到404页面
    const urlPara = getParams();
    if (to.path === "/" || to.path === "/pay") {
            if (isMobileDevice()) {
                if (urlPara.channelId) {
                    next({
                        path: "/Mobile",
                        query: {
                            channelId: urlPara.channelId
                        }
                    })
                } else {
                    next("/Mobile")
                }

            }
    }
    if (to.path === "/Mobile") {
        if (!isMobileDevice()) {
            if (urlPara.channelId) {
                next({
                    path: "/",
                    query: {
                        channelId: urlPara.channelId
                    }
                })
            } else {
                next("/")
            }

        }
    }
    if (to.path === "/details") {
        if (to.query.userId === undefined) {
            next("/Mobile")
        }
    }
    if (to.path === "/skills") {
        if (to.query.id === undefined) {
            next("/Mobile")
        }
    }
    if (to.path === "/authentication") {
        document.title = to.meta.title
        next()
    }

    if (to.path === "/startpage") {
        if (isMobileDevice()) {
            if (urlPara.channelId) {
                next({
                    path: "/startpageMobile",
                    query: {
                        channelId: urlPara.channelId
                    }
                })
            } else {
                next("/startpageMobile")
            }

        }
    }
    if (to.path === "/startpageMobile") {
        if (!isMobileDevice()) {
            if (urlPara.channelId) {
                next({
                    path: "/startpage",
                    query: {
                        channelId: urlPara.channelId
                    }
                })
            } else {
                next("/startpage")
            }

        }
    }
    if (to.matched.length === 0) {
        //这里打印输出
        // console.log(to, from, next);
        //这里用三元表达式进行了判断，当from.name也就是跳转来的路由名称不为空，则直接跳转到from所代表的路由，否则跳转到404页面
        // from.name ? next({name: from.name}) : next("/404");
    } else {
        //如果to的路由名称不为空，则进行跳转
        next();
    }
})
export default vueRouter